<template>
  <div>
    <p>{{ $i18n.locale === 'en' ? $route.meta.title['en'] : $route.meta.title['zh'] }}</p>
    <router-link to="report_detail">详情页面</router-link>
    <div
      @click="shrink"
      :style="{ width: isShrink ? '30px' : '100%' }"
      class="shrinkBox"
      style="border: 1px solid red; overflow: hidden"
    >
      <div style="background: lightblue; white-space: nowrap">
        电视剧啊靠的就是垃圾哦我敖电视剧啊靠的就是垃圾哦我敖电视剧啊靠的就是垃圾哦我敖
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-list',
  data() {
    return {
      isShrink: false,
    }
  },
  mounted() {
    console.log('1', this.$route)
  },
  methods: {
    shrink() {
      this.isShrink = !this.isShrink
    },
  },
}
</script>

<style scoped>
.shrinkBox {
  transition: width 1s;
}
</style>
